/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// images
import CoffeeDrink1 from "assets/images/bevibene/coffecello_11.jpg";
import CoffeeDrink2 from "assets/images/bevibene/coffeecello_8.jpg";
import CoffeeDrink3 from "assets/images/bevibene/coffeecello_20.jpg";
import CoffeeDrink4 from "assets/images/bevibene/coffeecello_12.jpg";

function Steps() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const slides = [
    {
      image: CoffeeDrink3,
      label: "Versão caribenha da receita mais famosa da Irlanda.",
      title: "Café Caribenho",
      description: "Misture todos os ingredientes. Decore com chantilly",
      ingredient1: "- 1 dose de Rum Ouro",
      ingredient2: "- 1 dose de Caffecello Bevibene",
      ingredient3: "- 1 xícara de café expresso",
      ingredient4: "- 2 colheres de chá de chatilly",
    },
    {
      image: CoffeeDrink2,
      label: "Para uma tarde de verão, sempre bem acompanhado.",
      title: "Mexican Mule",
      description: "Mixer com todos os ingrediente. Sirva com bastante gelo.",
      ingredient1: "- 1 dose de Tequilla",
      ingredient2: "- 2 doses de Caffecello Bevibene",
      ingredient3: "- 1 limão cortado em 4 e 1 colher de sopa de mel puro",
      ingredient4: "- Club Soda",
    },
    {
      image: CoffeeDrink1,
      label: "Um shot inesquecível!",
      title: "Fifth Avenue",
      description: "Misture todos os ingredientes. Decore com chantilly",
      ingredient1: "- 1 dose de Caffecello Bevibene",
      ingredient2: "- 1 dose de Baileys",
      ingredient3: "- chantilly",
      ingredient4: "",
    },
    {
      image: CoffeeDrink4,
      label: "Para esquentar as noites mais frios do ano",
      title: "Café Irlandês",
      description: "",
      ingredient1: "- 1 dose e meia de whiskey 12 anos",
      ingredient2: "- 1 dose de Caffecello Bevibene",
      ingredient3: "- 1 xícara de café expresso",
      ingredient4: "- 2 colheres de creme de leite fresco",
    },
  ];

  const steps = [
    {
      number: "1",
      label: "Café Caribenho",
    },
    {
      number: "2",
      label: "Mexican Mule",
    },
    {
      number: "3",
      label: "Fifth Avenue",
    },
    {
      number: "4",
      label: "Café Irlandes",
    },
  ];

  // Swiper navigation buttons styles
  const navigationStyles = {
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  return (
    <MKBox component="section" py={4} position="relative">
      <Container>
        <Swiper
          onInit={(swiper) => {
            setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={1200}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          loop
        >
          {slides.map(
            ({
              image,
              label,
              title,
              description,
              ingredient1,
              ingredient2,
              ingredient3,
              ingredient4,
            }) => (
              <SwiperSlide key={label}>
                <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
                  <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                    <MKBox p={1}>
                      <MKBox
                        component="img"
                        src={image}
                        alt={title}
                        width="100%"
                        borderRadius="xl"
                        maxHeight="37.5rem"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
                    <MKTypography
                      component="h6"
                      variant="h6"
                      opacity={0.7}
                      textTransform="uppercase"
                      fontWeight="bold"
                    >
                      {label}
                    </MKTypography>
                    <MKTypography
                      variant="h1"
                      fontWeight="bold"
                      sx={{
                        fontSize: ({ typography: { d4, d5 } }) => ({
                          xs: d5.fontSize,
                          lg: d4.fontSize,
                        }),
                      }}
                    >
                      {title}
                    </MKTypography>
                    <MKTypography variant="body1" my={1}>
                      {ingredient1}
                    </MKTypography>
                    <MKTypography variant="body1" my={1}>
                      {ingredient2}
                    </MKTypography>
                    <MKTypography variant="body1" my={1}>
                      {ingredient3}
                    </MKTypography>
                    <MKTypography variant="body1" my={1}>
                      {ingredient4}
                    </MKTypography>
                    <MKTypography variant="body1" my={1}>
                      {description}
                    </MKTypography>
                  </Grid>
                </Grid>
              </SwiperSlide>
            )
          )}
          <MKBox
            display="flex"
            position="absolute"
            bottom={{ xs: 0, lg: "10%" }}
            right="5%"
            zIndex={2}
          >
            <MKTypography
              variant="h2"
              ref={navigationPrevRef}
              color="dark"
              mr={{ xs: 8, md: 2, lg: 8 }}
              sx={navigationStyles}
            >
              <MKBox className="fas fa-chevron-left" />
            </MKTypography>
            <MKTypography variant="h2" ref={navigationNextRef} color="dark" sx={navigationStyles}>
              <MKBox className="fas fa-chevron-right" />
            </MKTypography>
          </MKBox>
        </Swiper>
        <Grid container mt={16}>
          {steps.map(({ number, label }, index) => (
            <Grid key={label} item xs={6} lg={3} textAlign="center">
              <MKTypography
                variant="body1"
                color="success"
                fontWeight="bold"
                pl={2}
                textGradient
                sx={{ fontFamily: ({ typography: { h1 } }) => h1.fontFamily, cursor: "pointer" }}
                onClick={() => slideTo(index + 1)}
              >
                <MKBox component="span">{number}</MKBox>
                <MKBox component="span" ml={1}>
                  {label}
                </MKBox>
              </MKTypography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Steps;
