/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Coworking page sections
import Testimonials from "pages/LandingPage/sections/Testimonials";
import ProductsIntro from "pages/LandingPage/sections/ProductsIntro";
import NossosValores from "pages/LandingPage/sections/NossosValores";
import ContactUs from "pages/LandingPage/sections/ContactUs";

// Images
import bgHorizonte from "assets/images/bevibene/bg_background.webp";
import CompreAquiBanner from "../../features/CompreAquiBanner";

function LandingPage() {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Para acesar esta pagina é necessário ser maior de 18 anos
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voce confirma ser maior de 18 anos?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickOpen}>Não</Button>
            <Button onClick={handleClose} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <MKBox
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.0)
            )}, url(${bgHorizonte})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          p: -0,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 6,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ProductsIntro />
        <CompreAquiBanner />
        <NossosValores />
        <Testimonials />
        <ContactUs />
      </Card>
    </>
  );
}

export default LandingPage;
