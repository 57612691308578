import DefaultNavbar from "features/Navbars/DefaultNavbar";
import routes from "../../../routes";

function BevibeneNavbar() {
  return (
    <DefaultNavbar
      routes={routes}
      action={{
        type: "external",
        route: "https://loja.bevibene.com.br",
        label: "compre aqui",
        color: "info",
      }}
    />
  );
}

export default BevibeneNavbar;
