/* eslint-disable no-useless-escape */
import { useState } from "react";
import emailjs from "@emailjs/browser";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgSunset from "assets/images/bevibene/sunset_1.jpg";

const initialFormData = Object.freeze({
  name: "",
  email: "",
  phone: "",
  interest: "",
  message: "",
});

const EmailJsService = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EmaiJsTemplate = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const EmaiJsUserID = process.env.REACT_APP_EMAILJS_USER_ID;

function ContactUs() {
  const [formData, updateFormData] = useState(initialFormData);
  const [validateForm, updateValidateForm] = useState(false);

  const [nameValidateForm, updateNameValidateForm] = useState({
    nameError: false,
    nameSuccess: false,
  });
  const [emailValidateForm, updateEmailValidateForm] = useState({
    emailError: false,
    emailSuccess: false,
  });
  const [messageValidateForm, updateMessageValidateForm] = useState({
    messageError: false,
    messageSuccess: false,
  });

  const [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { open, vertical, horizontal } = state;

  const handleSnackBar = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleChange = (e) => {
    updateValidateForm(true);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (formData.name.length >= 4) {
      updateNameValidateForm({
        nameError: false,
        nameSuccess: true,
      });
    } else {
      updateValidateForm(false);
      updateNameValidateForm({
        nameError: true,
        nameSuccess: false,
      });
    }

    if (formData.message.length >= 4) {
      updateMessageValidateForm({
        messageError: false,
        messageSuccess: true,
      });
    } else {
      updateValidateForm(false);
      updateMessageValidateForm({
        messageError: true,
        messageSuccess: false,
      });
    }

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(formData.email)) {
      updateEmailValidateForm({
        emailSuccess: true,
        emailError: false,
      });
    } else {
      updateValidateForm(false);
      updateEmailValidateForm({
        emailSuccess: false,
        emailError: true,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm) {
      emailjs.send(EmailJsService, EmaiJsTemplate, formData, EmaiJsUserID).then(
        (result) => {
          // eslint-disable-next-line no-console
          console.log(result.text);
          updateFormData(initialFormData);
          updateNameValidateForm({
            nameError: false,
            nameSuccess: false,
          });
          updateEmailValidateForm({
            emailSuccess: false,
            emailError: false,
          });
          updateMessageValidateForm({
            messageError: false,
            messageSuccess: false,
          });
          handleSnackBar({
            vertical: "bottom",
            horizontal: "center",
          });
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error.text);
        }
      );
    }
  };

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      Mensagem enviada com sucesso. Muito obrigado.
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={handleClose}
      />
    </MKBox>
  );
  const toastStyles = ({
    palette: { info },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: info.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  return (
    <>
      <MKBox component="section">
        <Container>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            autoHideDuration={8000}
            message={toastTemplate}
            sx={toastStyles}
          />
        </Container>
      </MKBox>
      <MKBox component="section" py={{ xs: 1, lg: 2 }}>
        <Container sx={{ maxWidth: "100% !important" }}>
          <Grid container item xs={10} sx={{ mx: "auto" }}>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={7}>
                  <MKBox component="form" p={2} method="post" autocomplete="off">
                    <MKBox px={2} py={{ xs: 2, sm: 6 }}>
                      <MKTypography variant="h3" mb={1}>
                        Esperamos seu contato!
                      </MKTypography>
                      <MKTypography variant="body1" color="text" mb={1}>
                        Perguntas, comentários, sugestões são muito bem-vindos. Obrigado!
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={0} pb={2} px={3}>
                      <Grid container>
                        <Grid item xs={12} pr={1} mb={2}>
                          <MKInput
                            label="Nome"
                            placeholder="Favor inserir seu nome"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            name="name"
                            value={formData.name}
                            error={nameValidateForm.nameError}
                            success={nameValidateForm.nameSuccess}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            label="E-mail:"
                            placeholder="Favor informar seu email para contato"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            fontSize="1.8rem"
                            name="email"
                            value={formData.email}
                            error={emailValidateForm.emailError}
                            success={emailValidateForm.emailSuccess}
                            onChange={handleChange}
                            sx={{
                              mx: 0,
                              fontSize: "1.8rem",
                              color: "red",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            label="Telefone (opcional):"
                            type="tel"
                            placeholder="Favor informar seu telefone para contato"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            fontSize="1.8rem"
                            name="phone"
                            value={formData.phone}
                            error={false}
                            success={false}
                            onChange={handleChange}
                            sx={{
                              mx: 0,
                              fontSize: "1.8rem",
                              color: "red",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            label="Sua mensagem"
                            placeholder="Favor inserir a mensagem que deseja enviar"
                            InputLabelProps={{ shrink: true }}
                            name="message"
                            value={formData.message}
                            error={messageValidateForm.messageError}
                            success={messageValidateForm.messageSuccess}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            rows={8}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton variant="gradient" color="info" onClick={handleSubmit}>
                          Enviar
                        </MKButton>
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  padding={2}
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.3),
                        rgba(gradients.dark.state, 0.3)
                      )}, url(${bgSunset})`,
                    backgroundSize: "cover",
                  }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pl={9} pr={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography variant="h5" color="white" mb={1}>
                        Informações de contato
                      </MKTypography>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="h4" color="white">
                          <i className="fa fa-whatsapp" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="h5"
                          color="white"
                          opacity={0.8}
                          ml={0.5}
                          fontWeight="regular"
                        >
                          (+55) 14 99864-0744
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="h4" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="h5"
                          color="white"
                          opacity={0.8}
                          ml={0.5}
                          fontWeight="regular"
                        >
                          bevibenebr@gmail.com
                        </MKTypography>
                      </MKBox>
                      <MKTypography
                        variant="h4"
                        fontWeight="bold"
                        color="white"
                        opacity={0.8}
                        mb={4}
                        mt={4}
                      >
                        Siga nossas redes e esteja sempre ligado nas novidades!
                      </MKTypography>
                      <Grid container item xs={12} paddingBottom={4} justifyContent="center">
                        <Grid item xs>
                          <MKButton
                            variant="text"
                            color="white"
                            size="large"
                            iconOnly
                            target="_blank"
                            href="https://www.instagram.com/bebidasbevibene/"
                          >
                            <i className="fab fa-instagram" style={{ fontSize: "3rem" }} />
                          </MKButton>
                        </Grid>
                        <Grid item xs>
                          <MKButton
                            variant="text"
                            color="white"
                            size="large"
                            iconOnly
                            target="_blank"
                            href="https://www.facebook.com/bebidasbevibene/"
                          >
                            <i className="fab fa-facebook" style={{ fontSize: "3rem" }} />
                          </MKButton>
                        </Grid>
                        <Grid item xs />
                        <Grid item xs />
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ContactUs;
