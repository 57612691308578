/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleBlogCard from "features/Cards/BlogCards/SimpleBlogCard";

// Images
import Limoncello1 from "assets/images/bevibene/limoncello-1-pq.webp";
import Arrancello1 from "assets/images/bevibene/arrancello-1-pq.webp";
import Coffeecello1 from "assets/images/bevibene/caffecello-1-pq.webp";

function ProductsIntro() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={10}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Celebre os bons momentos da vida!"
            container
            circular
            size="lg"
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" mb={1}>
            Adquira uma experiência única de sabor e requinte com nossos licores!{" "}
          </MKTypography>
          <MKTypography variant="h4" color="text" fontWeight="light">
            Todos nossos produto são elaborados a partir de ingredientes selecionados, naturais e
            frescos. Produzimos em pequenos lotes de origem controlada e apreciamos muito a
            variedade de sabores e aromas que conseguimos.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={Limoncello1}
                title="Limoncello"
                description="Nossa receita não leva corantes nem conservantes. Produzimos com frutas selecionadas. Consuma sempre muito gelado e usado para preparar deliciosos drinks."
                action={{
                  type: "internal",
                  route: "/produtos/limoncello",
                  color: "dark",
                  label: "Conheça mais",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={Arrancello1}
                title="Arancello"
                description="Para criar licores únicos e surpreendentes, combinamos os melhores ingredientes e processos de produção. As frutas são colhidas sempre em pequenos lotes."
                action={{
                  type: "internal",
                  route: "/produtos/arancello",
                  color: "dark",
                  label: "Conheça mais",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={Coffeecello1}
                title="Caffecello"
                description="Como somos exigentes com o café que bebemos. Foi muito fácil transformar o café em licor. Consuma gelado depois das refeições ou em drinks. Fica maravilhoso!"
                action={{
                  type: "internal",
                  route: "/produtos/coffeecello",
                  color: "dark",
                  label: "Conheça mais",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ProductsIntro;
