// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "features/Cards/InfoCards/DefaultInfoCard";

import MKTypography from "../../../components/MKTypography";

function NossosValores() {
  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          paddingTop={3}
          xs={12}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            Nossos Valores{" "}
          </MKTypography>
          <MKTypography variant="h4" fontWeight="light" color="text" mb={2}>
            Acreditamos que bons relacionamentos são construídos com momentos incríveis. Nossa
            missão é oferecer bebidas que vinculam nossas emoções positivas com a nossa memória
            sensitiva e assim perpetuar no sabor, o amor.
          </MKTypography>
        </Grid>
        <Grid item container direction="row" xs spacing={2} paddingTop={5}>
          <Grid item xs>
            <DefaultInfoCard
              icon="loyalty"
              title="Produto artesanal. Feito com amor!"
              description="Delicados, vivos e criativos os licores se prestam a todos os gostos, impulsionados por bartenders que viram nesse espírito uma fonte inesgotável de inspiração.
Nosso entusiasmo com o licor nasceu no momento que desejamos guardar os aromas e sabores das frutas da fazenda.  Criamos novos rituais de degustação, com itens capazes de brilhar em qualquer ocasião."
            />
          </Grid>
          <Grid item xs>
            <DefaultInfoCard
              icon="grass"
              title="100% Ingredientes Naturais"
              description="Somos da terra e dela vivemos, não procuramos nem aceitamos conservantes ou corantes. Mesmo que para obter um produto natural tenhamos um processo mais demorado ou mais elaborado, não importa, desde que o resultado final seja excepcional.
Conviver com a diversidades de sabores que o natural tem, nos leva a descobrir novos horizontes e desafios."
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" xs spacing={2} paddingTop={5}>
          <Grid item xs>
            <DefaultInfoCard
              icon="agriculture"
              title="Produzimos onde Cultivamos"
              description="Com mais de trinta anos estudo, cultivo e produção de frutas cítricas conseguimos a excelência de colher frutas de cor, sabor e condições sanitárias excepcionais. Nossa inspiração veio com a colheita dos limões Sicilianos e o aproveitamento das frutas, sempre frescas."
            />
          </Grid>
          <Grid item xs>
            <DefaultInfoCard
              icon="emoji_nature"
              title="Trabalhamos em Harmonia com a Natureza"
              description="Nossas frutas são produzidas com as melhores práticas agrícolas e colhidas sempre em pequenos lotes selecionados, respeitando o ciclo perfeito da planta em sintonia com o meio ambiente. Preservamos fontes hídricas e reservas naturais."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default NossosValores;
