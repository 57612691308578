/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Images
import Brinde1 from "assets/images/bevibene/brinde-1-pq.webp";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Testimonials() {
  const image = Brinde1;
  return (
    <MKBox component="section" position="relative" py={8}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }}>
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 12 }}>
                <MKBox
                  component="img"
                  src={image}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-12}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={8}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="bold"
                  mb={2}
                  sx={{ fontSize: "1.5rem" }}
                >
                  Um Brinde a Vida!
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Produzir licores apreciados mundialmente, de forma artesanal em Avaré/SP, me
                  despertou a vontade e o prazer de criar a empresa Bevibene, que do italiano
                  significa beber com prazer. Nosso o objetivo é produzir bebidas naturais e
                  saborosas para encartar as pessoas nos seus melhores momento da vida.
                </MKTypography>
                <MKTypography variant="h5" fontWeight="bold" color="white">
                  Monica -{" "}
                  <MKTypography variant="caption" color="white">
                    Fundadora Bevibene
                  </MKTypography>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
