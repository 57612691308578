// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function CompreAquiBanner() {
  return (
    <Container>
      <MKBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="xl"
        my={10}
        p={6}
        sx={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1533563906091-fdfdffc3e3c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80)",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8} lg={5}>
            <MKTypography variant="h5" color="white" fontWeight="bold">
              Na nossa loja você pode encontrar todos nossos produtos e várias opções legais de kits
              para presentes. Esperamos sua visita!
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto" }}>
            <MKBox width="12rem" ml="auto">
              <MKButton
                variant="gradient"
                color="warning"
                fullWidth
                sx={{ boxShadow: "none" }}
                href="https://bevibene.commercesuite.com.br"
                target="_blank"
              >
                Compre Aqui
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </Container>
  );
}

export default CompreAquiBanner;
