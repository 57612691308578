import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import BevibeneBackgroundCard from "features/Cards/BevibeneBackgroundCard";
import CompreAquiBanner from "features/CompreAquiBanner";

import bgFront from "assets/images/bevibene/pomar_2.jpg";
import Limoncello3 from "assets/images/bevibene/limoncello_3.jpg";
import Todos1 from "assets/images/bevibene/todos-1.webp";
import Drink1 from "assets/images/bevibene/sgrupino_1.jpg";
import bgImage from "assets/images/bevibene/limoncello-bg.webp";

import Steps from "./sections/Steps";
import ProductExperienceList from "./sections/ProductExperience";
import RedeSocialesCard from "./sections/RedesSocialesCard";

import MKBadge from "../../../components/MKBadge";

function LimoncelloPage() {
  return (
    <>
      <Helmet>
        <title>Limoncello Bevibene</title>
        <meta
          name="description"
          content="Limocello artesanal. Licor de limão 100% natural. Do campo para sua mesa."
        />
      </Helmet>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.0),
              rgba(gradients.dark.state, 0.0)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            alignItems="flex-start"
            flexDirection="column"
            sx={{ px: { xs: 3, lg: 0 } }}
            spacing={3}
          >
            <MKTypography variant="h4" color="white">
              Licor de limão siciliano, a tradicional preparação Italiana
            </MKTypography>
            <MKTypography
              variant="h1"
              color="white"
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Limoncello
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8}>
              Um brinde de refrescancia e sabor!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={3} padding={1}>
          <Grid item xs={12} md={4} lg={4} paddingTop={4}>
            <BevibeneBackgroundCard
              image={Limoncello3}
              icon="touch_app"
              title={
                <>
                  {" "}
                  Limão Siciliano, variedade Lisboa. Sempre fresco, colhido no ponto ótimo de
                  maturação.{" "}
                </>
              }
              description=""
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4} paddingTop={4}>
            <BevibeneBackgroundCard
              image={Drink1}
              icon="touch_app"
              title={<> Esperimente puro ou em elaborados drinks. O Sgroppino é fantástico! </>}
              description=""
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4} paddingTop={4}>
            <BevibeneBackgroundCard
              image={Todos1}
              icon="touch_app"
              title={<> Licores naturais, produzidos na Fazenda </>}
              description=""
            />
          </Grid>
        </Grid>
        <Container>
          <Grid container item flexDirection="column" xs={12} lg={8} mt={4}>
            <MKTypography
              component="h3"
              variant="button"
              textTransform="uppercase"
              fontWeight="bold"
              opacity={0.7}
              mb={1}
            >
              O Melhor!
            </MKTypography>
            <MKTypography variant="h2" mb={1}>
              Experiência Limoncello
            </MKTypography>
            <MKTypography variant="body1">
              A melhor maneira de disfrutar a experiência Limoncello Bevibene é servir sempre muito
              gelado.
            </MKTypography>
          </Grid>
          <MKBox component="section" py={6}>
            <Container>
              <Grid container spacing={2}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{ ml: { xs: -1, lg: "auto" }, mt: { xs: 0, lg: 2 }, mb: { xs: 2, lg: 4 } }}
                  >
                    <Stack>
                      <ProductExperienceList
                        icon="mediation"
                        content={
                          <>
                            Deixe ao menos 6 horas no freezer antes
                            <br />
                            de consumir seu Limoncello.
                          </>
                        }
                      />
                      <ProductExperienceList
                        icon="settings_overscan"
                        content={
                          <>
                            Acrecente seu Limoncello nas suas receitas
                            <br />
                            de drinks e encontre combinações incríveis.
                          </>
                        }
                      />
                      <ProductExperienceList
                        icon="token"
                        content={
                          <>
                            Também pode ser servido com gelo.
                            <br />
                            Refrescante e muito original em dias quentes.
                          </>
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={5}
                    sx={{
                      ml: { xs: "auto", lg: "auto" },
                      mt: { xs: 2, lg: 2 },
                      mb: { xs: 2, lg: 4 },
                    }}
                  >
                    <RedeSocialesCard
                      image={bgFront}
                      label="Redes Sociais"
                      title="Curta e compartilhe nossas redes sociais!"
                      description="Receitas, novidades, estilo e muitas outras coisas!"
                      action={{
                        type: "external",
                        route: "/pages/support/help-center",
                        label: "get started",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={10}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Nossa seleção de drinks"
            container
            circular
            size="lg"
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" mt={2}>
            Experimente novos sabores!{" "}
          </MKTypography>
        </Grid>
        <Steps />
        <CompreAquiBanner />
      </Card>
    </>
  );
}

export default LimoncelloPage;
