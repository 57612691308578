/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// Google Analytics
// https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics.html
// https://medium.com/geekculture/how-to-use-google-analytics-on-reactjs-in-5-minutes-7f6b43017ba9
import ReactGA from "react-ga";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

import routes from "routes";
import BevibeneNavbar from "features/Navbars/BevibeneNavbar";
import CenteredFooter from "features/Footers/CenteredFooter";
import LandingPage from "pages/LandingPage";
import Limoncello from "pages/Products/Limoncello";
import Arancello from "pages/Products/Arancello";
import Coffeecello from "pages/Products/Coffeecello";

const TRACKING_ID = "G-VRRTCPZ3L8"; // OUR_TRACKING_ID, Bevibene campain
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BevibeneNavbar />
      <Routes>
        {getRoutes(routes)}
        <Route path="/home" element={<LandingPage />} />
        <Route path="/produtos/limoncello" element={<Limoncello />} />
        <Route path="/produtos/arancello" element={<Arancello />} />
        <Route path="/produtos/caffecello" element={<Coffeecello />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      <CenteredFooter />
    </ThemeProvider>
  );
}
